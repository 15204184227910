import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import {Button,Input,Form,Layout,Checkbox,Typography,Result,Spin,Space} from 'antd'

const { Title,Text } = Typography;
const { Header, Content } = Layout;

function App() {

    const [show,setShow]=useState(false);
    const [done,setDone]=useState(false);
    const [failed,setFailed]=useState(false);
    const [loading,setLoading]=useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
        setLoading(true);

        fetch('https://request-lab-red.docks.manyos.io/bmc22',{
            method:"POST",
            mode:"cors",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify(values)
        }).then(result=>{
            console.log(result);
            if (result.status=="200"){
                setDone(true);
                setLoading(false);
                setFailed(false);
            }else{
                setDone(false);
                setLoading(false);
                setFailed(true);
            }

        }).catch((error)=> {
            console.log(error)
            setDone(false);
            setLoading(false);
            setFailed(true);
            }
        )
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

  return (
    <div className="App" >

      <Layout style={{backgroundColor:"#fff"}}>
        <Header style={{backgroundColor:"gray"}}>
            <img src={logo} style={{
                height: "54px",
                textAlign:"left",
                display: "block",
                marginLeft: "-19px",
                marginTop: "3px"}}/>
        </Header>
        <Content>
            {
                (loading)
                    ?(
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    )
                    :(
                (failed) ?
                (
                    <Result
                        status="error"
                        title="Leider ist etwas schief gelaufen!"
                        subTitle="Bitte versuchen Sie es erneut."
                        extra={[
                            <Button type="primary" key="console" onClick={()=>{
                                setDone(false);
                                setFailed(false);
                                setLoading(false);
                            }
                            }>
                                Zurück
                            </Button>

                        ]}
                    />
                )
                :(
                (!done) ? (

                    <div>

                        <Space direction="vertical">
                            <Title level={3} style={{marginTop:"20px"}}>
                                Wir versüßen Ihren Sommer!
                            </Title>
                            <Text >
                                Registrieren Sie sich hier, und gewinnen Sie Eis für Sie und Ihre Kollegen!
                            </Text>
                            <Text italic>
                                Falls Sie der glückliche Gewinner sind, schicken wir Ihnen einen Eiswagen zu Ihrer Firma!
                            </Text>
                        </Space>


                <Form
                name="basic"
                labelCol={{ offset:0,span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                style={{marginLeft:"10px",marginRight:"10px", marginTop:"25px"}}
                >
                <Form.Item
                label="Firma"
                name="Firma"
                rules={[{ min: 3,required: true, message: 'Bitte geben Sie Ihre Firma an' }]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Vorname"
                name="Vorname"
                rules={[{min:3, required: true, message: 'Bitte geben Sie Ihren Vornamen an' }]}
                >
                <Input />
                </Form.Item>

                <Form.Item
                label="Nachname"
                name="Nachname"
                rules={[{min:3, required: true, message: 'Bitte geben Sie Ihren Nachnamen an' }]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="E-Mail"
                name="Email"
                rules={[{
                type: 'email',
                message: 'Die Eingabe ist nicht gültig',
            },{ required: true, message: 'Bitte geben Sie Ihre E-Mail Adresse an' }]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Telefonnummer"
                name="Telefonnummer"
                rules={[{min:7,type:'string', message: 'Die Eingabe ist nicht gültig'},{ required: true, message: 'Bitte geben Sie Ihre Telefonnummer an' }]}
                >
                <Input />
                </Form.Item>
                <Form.Item
                label="Rolle"
                name="Rolle"
                rules={[{ min:3,required: true, message: 'Bitte geben Sie Ihre Rolle an' }]}
                >
                <Input />
                </Form.Item>
                <Form.Item name="disclaimer"  wrapperCol={{ offset: 0, span: 10}}
                valuePropName="checked"
                rules={
                [{
                validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Sie müssen die Teilnahmebedinungen akzeptieren'))
            }]
            }>
                <Checkbox>Ich akzeptiere die <a href="#cond" onClick={
                (e)=>{

                setShow(!show);
            }}
                style={{
                color:"#ef7721"
            }}>
                Teilnahme- und Datenschutzbedinungen
                </a></Checkbox>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 0, span: 4 }}>
                <Button type="primary" htmlType="submit" style={{backgroundColor:"#ef7721", borderColor:"#ef7721"}}>
                Registrieren
                </Button>
                </Form.Item>
                </Form>
                <div style={{display:show?"block":"none"}}>
                <Space direction="vertical">
                <Title level={3} id="cond">
                Teilnahme- und Datenschutzbedingungen
                </Title>
                <Text>
                1. VERANSTALTER
                "manyos versüßt den Sommer" ist ein Gewinnspiel der manyos technology GmbH
                (nachfolgend manyos)
                </Text>
                <br/>

                <Text>
                2. TEILNAHMEBERECHTIGUNG
                Teilnehmerin oder Teilnehmer kann jede natürliche Person sein, die das 18. Lebensjahr vollendet
                hat. Ausgenommen sind Mitarbeiterinnen und Mitarbeiter sowie Bevollmächtigte der manyos, deren
                Familienmitglieder und Haushaltsangehörige. Die manyos behält sich vor, aus wichtigem Grund die
                Teilnahme von einzelnen Personen von vornherein abzulehnen oder sie später vom Gewinnspiel
                auszuschließen und auch den Gewinn zurückzufordern. Ein wichtiger Grund liegt zum Beispiel in
                Fällen von Missbrauch oder Betrug (auch schon bei Versuchen) oder sonstigen Verstößen gegen
                die Teilnahmebedingungen vor. Die manyos behält sich vor, in diesem Fällen auch nachträglich
                Gewinne abzuerkennen.
                </Text>
                <br/>

                <Text>
                3. INHALT DES GEWINNSPIELS
                Die Teilnahme am Gewinnspiel erfolgt durch das Ausfüllen des Formulars auf der Website: eis.manyos.docks.io .
                Das Formular muss am 02.06.2022 ausgefüllt sein. Die Ziehung des Gewinners findet zwischen 03.06.2022 und 20.06.2022
                statt.
                Teilnahmeberechtigt sind alle Teilnehmer auf dem BMC DSOM Day am 02.06.2022 in Köln.
                Eine Auszahlung des Gewinns in bar oder die Übertragung auf andere Personen ist nicht möglich.
                </Text>
                <br/>

                <Text>
                4. TEILNAHME; GEWINNERMITTLUNG
                Mit dem Ausfüllen des Formulars erklärt sich der Nutzer einverstanden, dass
                    <br/>
                • Im Falle des Gewinns zwecks Gewinnbenachrichtigung sein vollständiger Vor- und
                Nachname, der Name der Firma, auf den
                sozialen Kanälen von manyos veröffentlicht werden darf
                    <br/>
                • Sein vollständiger Name und seine E-Mail Adresse und der Name der Firma, und Telefonnummer zur Kontaktaufnahme verwendet werden darf.
                </Text>
                <br/>

                <Text>
                5. DATENSCHUTZ
                Datenschutzinformationen: Der Veranstalter verarbeitet personenbezogene Daten für Zwecke
                dieses Gewinnspiels auf Basis der Einwilligung jeder Teilnehmerin und jedes Teilnehmers gemäß
                Art. 6 Absatz 1a EU-DSGVO. <br/> Ggf werden wir die Daten dazu verwenden um Sie persönlich zu kontaktieren. Ihre Daten werden auf keinen Fall an Dritte weitergegeben.
                </Text>
                <br/>

                <Text>
                6. BENACHRICHTIGUNG DER GEWINNER
                Die Ziehung des Gewinners findet zwischen 03.06.2022 und 20.06.2022
                statt.
                Gewinner werden zeitnah per E-Mail informiert.
                </Text>
                <br/>

                <Text>
                7. TEILNAHMEFRIST
                Das Gewinnspiel beginnt am 02.06.2022. Die Teilnahmefrist endet am 02.06.2022 um 23:59 Uhr.
                </Text>
                <br/>

                <Text>
                8. HAFTUNGSAUSSCHLUSS / ÄNDERUNG UND VORZEITIGE BEENDIGUNG DES
                GEWINNSPIELS
                Der Veranstalter haftet nicht für etwaige Übertragungsfehler. Der Veranstalter behält sich vor, das Gewinnspiel jederzeit, auch ohne
                Einhaltung von Fristen, ganz oder teilweise vorzeitig zu beenden oder in seinem Verlauf
                abzuändern, sofern es aus technischen (z.B. Computervirus, Manipulation von oder Fehler in
                Software und Hardware) oder rechtlichen Gründen nicht möglich ist, das Gewinnspieles
                ordnungsgemäß durchzuführen.
                </Text>
                <br/>

                <Text>
                9. SCHLUSSBESTIMMUNGEN
                Es gilt das Recht der Bundesrepublik Deutschland. Der Rechtsweg ist ausgeschlossen.</Text>
                </Space>
                </div>
                    </div>

                    )

                 : (
                        <div>
                            <Result
                                status="success"
                                title="Danke für die Teilnahme am Gewinnspiel"
                                subTitle="Wir haben Ihnen eine E-Mail mit einem Aktivierungslink gesendet. Diesen müssen Sie noch öffnen, um Ihre E-Mail Adresse zu bestätigen. Falls Sie gewonnen haben, werden wir Sie kontaktieren. Wir drücken Ihnen die Daumen"

                            />
                        </div>
                    )
                )
                )

            }

        </Content>
        
      </Layout>

    </div>
  );
}

export default App;
